'use strict';

$(
    function() {
        try {
            var topH = $('._ > .top').height();
            app.fixTop = topH || 0;
        } catch (e) {}
        app.assignNumberControl();
        app.setSelectedLink();
        app.addRegisterCheckBoxes();
        app.attachSvgEvents();
        app.makeSocialButtons();
        app.initACBL();
        app.setBackUrls();
        app.addCoverSpan();
        app.saveProductListInfo();
        app.topMenu.init();
        if (!app.isMobile()) {
            $(window).scroll(
                function() {
                    app.floatingHeader();
                }
            );
            app.floatingHeader();
        }
        app.assignProductOver();
        app.initializeScrollButton();
        app.hamburger.init({
            look: 1
        });
        app.color.init();
        app.template.set();
    }
);

var app = {
    _VERSION: 2,
    _vars: {
        v: '1.5',
        tmp: 30,
        putAttributesToProdItemsDirectly: true,
        _guid: '01558fdb-4477-405e-a769-e6729c7c652d',
        _customFonts: {
            FontAwesome: true
        },
    },
    responsiveMode: true,
    indexLinkPos: 0,
    messages: {},
    productAvailableQuantityUrl: null,
    optionsWereInitialized: false,
    waitBlockZIndex: 9999,
    template: {
        set: function() {
            this.collapse();
            this.viewMore();
            this.modifyPages();
            this.wrapMultiInputs();
            this.footerCorrections();
        },
        initSlider: function(params) {
            var {
                sliderHeight,
                sliderAutoplayTimeout,
                carouselAutoplayTimeout,
                leftMenu
            } = params || {};

            var fullWidth = leftMenu !== 'Y';

            app.events.addListener('udu_AFTER-MAKE-CAROUSEL-DONE', function(info) {
                heightCheck(info);
            });

            app.owlSlider.makeBanner({
                setHeight: sliderHeight,
                speed: sliderAutoplayTimeout
            });
            app.owlSlider.makeProductListSlider({
                oneRow: true,
                margin: 0,
                minItemCount: 2,
                loop: false,
                controlScreenWidth: {
                    mobDestroy: 'N',
                    width: 991
                }
            });
            app.owlSlider.makeCarousel({
                autoHeight: true,
                autoplay: true,
                speed: carouselAutoplayTimeout,
                slideBy: 1,
                smartSpeed: 1500,
                lazyLoad: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    544: {
                        items: 2
                    },
                    761: {
                        items: 3
                    },
                    992: {
                        items: fullWidth ? 4 : 3
                    },
                    1200: {
                        items: fullWidth ? 5 : 4
                    }
                }
            });

            function heightCheck(info) {
                info || (info = {});

                var data = info.data || '';
                if (!data) {
                    return;
                }

                var maxH = 0;
                var $slider = data.$slider;
                var $img = $('.owl-item img', $slider);
                var cntImg = $img.length;

                var $owlStageOuter = $('.owl-stage-outer', $slider);
                if (!$owlStageOuter.length) {
                    return;
                }

                var carTimer = setInterval(function() {
                    var gotheight = true;
                    var cntImgLoaded = 0;

                    $img.each(function() {
                        if (this.complete) {
                            cntImgLoaded++;
                        }
                    });

                    if ($owlStageOuter.height() < 2 || cntImg !== cntImgLoaded) {
                        gotheight = false;
                    }

                    if (gotheight) {
                        clearInterval(carTimer);

                        $img.each(function() {
                            var height = $(this).height();
                            if (height > maxH) {
                                maxH = height;
                            }

                            $('.owl-item > .item', $slider).css('height', maxH);
                        });
                    }
                }, 300);
            }
        },
        collapse: function() {
            $(document).on('click', '[data-udu-toggle="collapse"]', function() {
                var target = $(this).siblings('.is-collapse');
                if ($(this).attr('aria-expanded') === 'false') {
                    $(target).slideDown().addClass('in');
                    $(this).removeClass('is-collapsed').attr('aria-expanded', 'true');
                } else {
                    $(target).slideUp().removeClass('in');
                    $(this).addClass('is-collapsed').attr('aria-expanded', 'false');
                }
            });
        },
        viewMore: function() {
            if ($('.bg-index').length && app.viewMore) {
                var featuredCss = '.cat-featured-prods';
                var newCss = '.cat-new-prods';

                $(featuredCss).length && app.viewMore.init({
                    list: featuredCss + ' .product-list2',
                    btn: featuredCss + ' .loadmore-btn'
                });

                $(newCss).length && app.viewMore.init({
                    list: newCss + ' .product-list2',
                    btn: newCss + ' .loadmore-btn'
                });
            }
        },
        modifyPages: function() {
            var $tbl = $('.part-2 .ch-prod-list, .udu__cart-prod-list table.tbl-bordered');
            if ($tbl.length >= 1) {
                $tbl.find('.att-link').addClass('min-mode');
                $tbl.find('.image .att-link iframe,.image .att-link img').wrap('<div class="att-wrapper"></div>');
                $tbl.find('tr').each(
                    function() {
                        var $tr = $(this);
                        var $tdq = $tr.find('td.quantity');
                        if ($tdq.length === 1) {
                            $tr.find('.image li.att-link:first-child').append('<span class="qty">' + $tdq.html() + '</span>');
                        }
                    }
                );
            }

            var $qtyItem = $('#pr_form_id_quantity');
            if ($qtyItem.length === 1) {
                if (app.getLang() === 'en') {
                    var minMsg = 'The quantity you have entered is less than the minimum allowed to purchase.';
                } else {
                    minMsg = 'La quantité que vous avez entrée est moins de minimum autorisé à acheter.';
                }
                $qtyItem.data('udu-min-qty-msg', minMsg);
            }

            $('.div-cart-table td.description').append('<a class="lnk-remove">' + (app.getLang() === 'en' ? 'Remove' : 'Retirer') + '</a>');
            $('a.lnk-remove').click(
                function(e) {
                    $(this).closest('tr').find('.del-form button').click();
                    e.preventDefault();
                }
            );
        },
        wrapMultiInputs: function() {
            $('.mult-quantity input.number.text, .div-cart-table .tbl-bordered td input[name=quantity]')
                .wrap("<div class='mult-input-wrp div-dummy'></div>");

            $('.mult-input-wrp')
                .prepend('<button class="custom-button btn-minus btn-quant" type="button">-</button>')
                .append('<button class="custom-button btn-plus btn-quant" type="button">+</button>');

            $('.option-name > div').append('<div class="dv-tlt-qty">Quantity</div>');

            $('.div-cart-table td .mult-input-wrp input').change(
                function() {
                    app.doCartRecalc($(this).closest('form'));
                }
            );
            $('.div-cart-table td .mult-input-wrp button').click(
                function() {
                    app.doCartRecalc($(this).closest('form'));
                }
            );
        },
        footerCorrections: function() {
            changePadding('#footerLinks', '> .footer-links-column');

            function changePadding(el, findStr) {
                if (!$(el).find(findStr).length) {
                    $(el).css('padding', '0');
                }
            }
        }
    },
    _doNotRemoveEmptyMaxQntyForMultipleOrdering: true,
    doCartRecalc: function($form) {
        $form.submit();
    },
    doAfterProdListAttributesDone: function ($list, savedData) {
        $list.each(
            function () {
                var $this = $(this);
                var $item = $this.closest('.item');
                var href = $this.attr('href');
                if (!href) {
                    href = $this.data('href');
                }

                var prodId = app.prodListAttrs.getIdByHref(href);

                var str = '';
                if (savedData[prodId] && savedData[prodId]['bewg']) {
                    var vl = savedData[prodId]['bewg'];
                    if (Number(vl)) {
                        vl = app.money.toDollar(vl);
                    }
                    var msrp = 'BEWG';
                    str = '<span class="udu__msrp-label" data-udu-editor="editor2">' + msrp + '</span><span class="udu__msrp-value" data-udu-editor="editor2">' + vl  + '</span>';
                }
                if (str) {
                    $item.find('.price').after('<span class="udu__msrp">' + str + '</span>');
                }
            }
        );
    },
    doAfterInventoryItemSet: function() {
        $('.option-name .qty-available').addClass('disp');
    },
};
